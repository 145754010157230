@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700&display=swap");
:root {
	--foreground-rgb: 0, 0, 0;
	--background-start-rgb: 214, 219, 220;
	--background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
	:root {
		--foreground-rgb: 255, 255, 255;
		--background-start-rgb: 0, 0, 0;
		--background-end-rgb: 0, 0, 0;
	}
}

html {
	height: 100%;
	width: 100%;
}

body {
	height: 100%;
	margin: 0;
	font-family: "Montserrat", sans-serif !important;
	background-image: repeating-linear-gradient(
		-58deg,
		#fff9e9,
		#fff9e9 80px,
		black 80px,
		black 200px
	);
	background-position: top;
	background-attachment: fixed;
	background-size: 4000px 4000px;
	background-repeat: no-repeat;
}

body.no-overflow {
	overflow: hidden;
}

img {
	height: auto;
	max-width: 100%;
	height: auto;
}

p {
	margin: 0;
}
