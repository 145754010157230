@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900&display=swap");

@font-face {
	font-family: "ocra";
	src: url("./fonts/ocraext.ttf") format("truetype");
}

/* Navigation */

.topnav {
	/* put it in the center */
	position: fixed;
	left: 50%;
	top: 0;
	transform: translate(-50%, 0);
	width: 100%;
	max-width: 1200px;
	min-height: 100px;
	background-color: transparent;
	overflow: visible;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	z-index: 2;
}
.topnav > * {
	margin-left: 25px;
	margin-right: 25px;
}
/* .topnav > img {
	width: auto;
	height: auto;
} */
.hamburgerButton {
	margin-top: 10px;
}
.hamburgerContainer {
	height: 35px;
	max-height: 35px;
	width: 35px;
	margin-top: 15px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.hamburgerBox {
	height: 35px;
	max-height: 22px;
	width: 33px;
	max-width: 30px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-between;
	background-color: transparent;
	transition: all 0.2s ease-out;
	overflow: hidden;
}
.hamburgerBoxNoHover {
	height: 35px;
	max-height: 22px;
	width: 33px;
	max-width: 30px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-between;
	background-color: transparent;
	transition: all 0.2s ease-out;
	overflow: hidden;
}
@media (hover: hover) {
	.burgerBackground:hover .hamburgerBox {
		max-height: 26px;
		max-width: 31px;
		transition: all 0.15s ease-in;
	}
	.hamburgerBoxNoHover:hover > div {
		transition: all 0.15s ease-in;
	}
}
@media (hover: none) {
	.burgerBackground:active .hamburgerBox {
		max-height: 26px;
		max-width: 31px;
		transition: all 0.15s ease-in;
	}
	.hamburgerBoxNoHover:active > div {
		transition: all 0.15s ease-in;
	}
}
.toggled {
	max-width: 30px !important;
	max-height: 22px !important;
	transition: all 0.1s !important;
}
.toggled::after {
	max-height: 26px !important;
	max-width: 31px !important;
	transition: all 0.1s !important;
}

.hamburgerBox > div {
	width: 100%;
	min-height: 3px;
	max-height: 3px;
	background-color: white;
	border-radius: 2px;
}
.hamburgerBoxNoHover > div {
	width: 100%;
	min-height: 3px;
	max-height: 3px;
	background-color: white;
	transition: all 0.15s ease-in;
	border-radius: 2px;
}

.hamburgerMid {
	max-width: 30px;
}
.slideWrapperWrapper {
	position: fixed;
	overflow: hidden;
	top: 0;
	height: 100%;
	width: 100%;
	visibility: hidden;
	opacity: 0;
	transition: visibility 0s, opacity 1s cubic-bezier(0.19, 1, 0.22, 1);
	z-index: 1;
}
.slideWrapper {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;
}

.slide {
	position: absolute;
	top: 0%;
	width: 100%;
	height: 100%;
	background: #343434;
	transition: 1s;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

@media (hover: hover) {
	.slideWrapper:hover .slide {
		transition: 1s;
	}
}
@media (hover: none) {
	.slideWrapper:none .slide {
		transition: 1s;
	}
}

.closeButton {
	right: 0;
}

#menu_overlay {
	flex-direction: column;
	justify-content: flex-start;
	overflow: auto;
}

.link {
	font-size: 36px;
	font-weight: 350;
	color: white;
	text-decoration-line: underline;
	text-decoration-thickness: from-font;
}

.pointContainer {
	display: flex;
	align-items: center;
	margin-bottom: 30px;
}

.topLineX {
	animation-duration: 1.1s;
	animation-name: slideTopX;
	animation-iteration-count: 1;
	transform: translate(0, 10px) rotate(45deg);
}

@keyframes slideTopX {
	from {
		transform: translate(0, 0) rotate(0deg);
	}
	to {
		transform: translate(0, 10px) rotate(45deg);
	}
}

.topLineBurg {
	animation-duration: 1s;
	animation-name: slideOutTopX;
	animation-iteration-count: 1;
	transform: translate(0, 0) rotate(0deg);
}

@keyframes slideOutTopX {
	from {
		transform: translate(0, 10px) rotate(45deg);
	}
	to {
		transform: translate(0, 0) rotate(0deg);
	}
}

.midLineX {
	transform: translate(40px);
	animation-duration: 1s;
	animation-name: slideRightX;
	animation-iteration-count: 1;
}

@keyframes slideRightX {
	from {
		transform: translate(0, 0);
	}
	to {
		transform: translate(40px);
	}
}

.midLineBurg {
	transform: translate(0, 0);
	animation-duration: 1s;
	animation-name: slideLeftBurg;
	animation-iteration-count: 1;
}

@keyframes slideLeftBurg {
	from {
		transform: translate(40px);
	}
	to {
		transform: translate(0, 0);
	}
}

.bottomLineX {
	transform: translate(0, -9px) rotate(-45deg);
	animation-duration: 1.1s;
	animation-name: slideBottomX;
	animation-iteration-count: 1;
}

@keyframes slideBottomX {
	from {
		transform: translate(0, 0) rotate(0deg);
	}
	to {
		transform: translate(0, -9px) rotate(-45deg);
	}
}

.bottomLineBurg {
	animation-duration: 1s;
	animation-name: slideOutBottomX;
	animation-iteration-count: 1;
	transform: translate(0, 0) rotate(0deg);
}

@keyframes slideOutBottomX {
	from {
		transform: translate(0, -9px) rotate(-45deg);
	}
	to {
		transform: translate(0, 0) rotate(0);
	}
}

#menu_overlay > div {
	max-width: 1200px;
	width: 100%;
	padding-left: 25px;
	padding-right: 25px;
	padding-top: 30px;
	color: white;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.contentWidth {
	max-width: 1200px;
	width: 100%;
	padding-left: 25px;
	padding-right: 25px;
	padding-top: 180px !important;
	color: white;
}

.slideWrapperWrapper.overlayShow {
	visibility: visible;
	opacity: 1;
	transition: opacity 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}

.linkUlPoint {
	position: relative;
	min-width: 36px;
	max-width: 36px;
	max-height: 36px;
	min-height: 36px;
	display: inline-block;
	margin-right: 12px;
}

.ulPointBottom {
	position: absolute;
	min-width: 36px;
	max-width: 36px;
	max-height: 1px;
	min-height: 1px;
	bottom: 0;
	background-color: #da132b;
}
.ulPointRight {
	position: absolute;
	min-width: 36px;
	max-width: 36px;
	max-height: 1px;
	min-height: 1px;
	background-color: #da132b;
	right: -50%;
	bottom: 50%;
	transform: rotate(90deg);
}
.ulPointDiag {
	position: absolute;
	min-width: 36px;
	max-width: 36px;
	max-height: 1px;
	min-height: 1px;
	right: -15%;
	bottom: 35%;
	transform: rotate(45deg);
	background-color: #da132b;
}
.burgerBackground {
	height: 48px;
	width: 48px;
	max-height: 48px;
	max-width: 48px;
	min-height: 48px;
	min-width: 48px;
	border-radius: 50%;
	background-color: rgba(116, 116, 116, 0.8);
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.15s ease-in;
}
@media (hover: hover) {
	.burgerBackground:hover {
		height: 50px;
		width: 50px;
		max-height: 50px;
		max-width: 50px;
		min-height: 50px;
		min-width: 50px;
		transition: all 0.15s ease-in;
	}
}
@media (hover: none) {
	.burgerBackground:active {
		height: 50px;
		width: 50px;
		max-height: 50px;
		max-width: 50px;
		min-height: 50px;
		min-width: 50px;
		transition: all 0.15s ease-in;
	}
}

/* Layout */

.mainContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	z-index: 0;
}

.card {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: black;
	overflow: hidden;
}
.card_1 {
	width: 100%;
	padding-top: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: black;
}

.cardContent {
	max-width: 1200px;
	width: 100%;
	margin: auto;
	display: flex;
	justify-content: space-around;
	align-items: stretch;
}

.minHeight {
	min-height: 700px;
}

.cardContent_1 {
	max-width: 1180px;
	width: 100%;
	min-height: 650px;
	margin: auto;
	display: flex;
	justify-content: center;
	align-items: center;
}

/* CardContent */

.landingContent {
	padding-left: 25px;
	padding-right: 25px;
	color: white;
	font-size: 62px;
	font-weight: 700;
	line-height: 1em;
}

.landingTitle {
	font-size: 14px;
	font-weight: lighter;
	line-height: 1em;
	margin-top: 15px;
}
.standardContent {
	width: 100%;
	margin: 25px 25px 25px 25px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.carouselImage {
	max-height: 600px;
}

/* Common Cards */

.delimiter {
	transform: rotate(-90deg);
	font-size: 3px;
}

.dateRange {
	font-size: 10px;
}

.flexColumnItem {
	display: flex;
	align-items: center;
	line-height: 1em;
	width: 100%;
}
.title {
	margin-top: 12px;
	margin-bottom: 16px;
	font-size: 48px;
	font-weight: bold;
}

.lineDelimiter {
	font-size: 2.5px;
	line-height: 1em;
}
.subtitle {
	font-size: 32px;
	line-height: 1em;
	margin-top: 16px;
}
.roleBullet {
	max-width: 12px;
	min-width: 12px;
	max-height: 12px;
	min-height: 12px;
	display: inline-block;
	margin-right: 6px;
}
.role {
	margin-right: 10px;
	font-size: 13px;
}
.roleCol {
	margin-top: 35px;
}
.roleDesc {
	font-size: 13px;
}
.standardParagraph {
	font-size: 12px;
	color: #343434;
	margin-top: 32px;
}

/* Carousel */

.carouselContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.carouselWrapper {
	display: flex;
	width: 100%;
	position: relative;
}

.carouselContentWrapper {
	overflow: auto;
	width: 100%;
	height: 100%;
	-ms-overflow-style: none;
	scrollbar-width: none;
	scroll-snap-type: x mandatory;
}
.carouselContentWrapper::-webkit-scrollbar {
	display: none;
}

.carouselContent {
	display: flex;
	transition: all 250ms linear;
	-ms-overflow-style: none; /* hide scrollbar in IE and Edge */
	scrollbar-width: none; /* hide scrollbar in Firefox */
}

/* hide scrollbar in webkit browser */
.carouselContent::-webkit-scrollbar,
.carouselContent::-webkit-scrollbar {
	display: none;
}

.carouselContent > * {
	width: 100%;
	flex-shrink: 0;
	flex-grow: 1;
	scroll-snap-align: center;
}

.indexDotHighlighted {
	width: 10px;
	min-width: 10px;
	height: 10px;
	min-height: 10px;
	border-radius: 50%;
	margin: 10px;
	background-color: black;
}

.indexDot {
	width: 10px;
	min-width: 10px;
	height: 10px;
	min-height: 10px;
	border-radius: 50%;
	margin: 10px;
	background-color: #343434;
	opacity: 0.7;
}
@media (hover: hover) {
	.indexDot:hover {
		background-color: black;
		opacity: 0.9;
	}
}

@media (hover: none) {
	.indexDot:active {
		background-color: black;
		opacity: 0.9;
	}
}

.carouselPaginationWrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.carouselImage {
	display: flex;
	justify-content: center;
	align-items: center;
}

button {
	padding: 0;
	margin: 0;
	border: none;
}

/* fonts */

body {
	font-family: "Montserrat";
}

.font-content {
	font-family: "Montserrat", sans-serif !important;
}

.font-mono {
	font-family: "ocra" !important;
}
